import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { OrderPresenter } from '@/app/ui/presenters/OrderPresenter'
import { Orders, Order, OrderDetail } from '@/domain/entities/Order'
import { Pagination } from '@/domain/entities/Pagination'
import { ORDER_PAGINATION } from '@/app/infrastructures/misc/Constants/pagination'
import { CancelOrderApiRequest } from '@/data/payload/api/OrderRequest'

export interface OrderState {
  isLoading: boolean
  isLoadingExport: boolean
  paginationData: Pagination
  orderData: Order[]
  orderDetail: OrderDetail
  statusList: string[]
}

@Module({ namespaced: true, store, name: 'order', dynamic: true })
class OrderController extends VuexModule implements OrderState {
  private presenter: OrderPresenter = container.resolve(OrderPresenter)
  public isLoading = false
  public isLoadingExport = false
  public paginationData = new Pagination(1, ORDER_PAGINATION)
  public orderData: Order[] = []
  public orderDetail = new OrderDetail()
  public statusList = [
    'Dibuat',
    'Dibayar',
    'Diproses',
    'Dijemput',
    'Dikirim',
    'Selesai',
    'Dibatalkan Pembeli',
    'Dibatalkan Admin',
    'Dibatalkan Sistem',
  ]
  public statusCancelOrder = ''

  @Action({ rawError: true })
  public getOrderList(params: Record<string, string | number | undefined>) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setLoading(false)
        this.setOrderData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Order Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getOrderDetail(id: string) {
    this.setLoading(true)
    this.presenter
      .get(id)
      .then(res => {
        this.setOrderDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Order Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public cancelOrder(form: { shipmentId: string; note: string }) {
    this.setLoading(true)
    this.presenter
      .cancelOrder(new CancelOrderApiRequest(form.shipmentId, 'ZZZZZ', form.note))
      .then(() => {
        this.setStatusCancelOrder(EventBusConstants.CANCEL_ORDER_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Cancel Order Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public exportOrder(params: Record<string, string | number | undefined>) {
    this.setLoadingExport(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .exportOrder(formattedParams)
      .then((url) => {
        window.location.href = url
      })
      .catch(error => {
        Vue.notify({
          title: 'Export Order Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingExport(false)
      })
  }

  @Mutation
  private setOrderData(orders: Orders) {
    this.paginationData = <Pagination>orders.pagination
    this.orderData = <Order[]>orders.data
  }

  @Mutation
  private setOrderDetail(order: OrderDetail) {
    this.orderDetail = order
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setLoadingExport(bool: boolean) {
    this.isLoadingExport = bool
  }

  @Mutation
  public setStatusCancelOrder(status: string) {
    this.statusCancelOrder = status
  }
}

export default getModule(OrderController)
