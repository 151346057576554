


























































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import CourierIcon from '@/app/ui/assets/courier_icon.vue'
import controller from '@/app/ui/controllers/OrderController'
import { OrderDetail, ShortHistory } from '@/domain/entities/Order'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import dayjs from 'dayjs'
import { StatusDetail, STATUS } from "@/app/infrastructures/misc/Constants/orderManagement";

interface statusLabel {
  title: string
  value: string
}

@Component({
  components: {
    Button,
    LoadingOverlay,
    Modal,
    TextInput,
    CourierIcon,
  },
})
export default class OrderManagementDetailPage extends Vue {
  controller = controller

  modal = ''
  cancelReason = ''

  created(): void {
    controller.getOrderDetail(this.$route.params.id)
  }

  @Watch('controller.statusCancelOrder')
  onStatusCancelOrderChanged(status: string): void {
    if (status !== '' && status === EventBusConstants.CANCEL_ORDER_SUCCESS) {
      this.modal = ''
      this.$notify({
        title: 'Order Cancelled',
        text: `Order ${controller.orderDetail.shipmentId} is successfully cancelled`,
        type: 'success',
        duration: 5000,
      })

      controller.getOrderDetail(this.$route.params.id)
    }
    controller.setStatusCancelOrder('')
  }

  get orderDetail(): OrderDetail {
    return controller.orderDetail
  }

  get paymentMethods(): string[] {
    return controller?.orderDetail?.paymentMethod?.split(',') || []
  }

  get buyerPhoneNumber(): string {
    return Utils.countryIndonesiaPhoneNumber(
      controller.orderDetail.buyerPhoneNumber || '',
      true
    )
  }

  get status(): ShortHistory {
    if (controller.orderDetail.shortHistories) {
      return controller.orderDetail.shortHistories[0]
    }

    return {
      shortStatus: '',
      historyTime: '',
      statusId: '',
      reason: '',
    }
  }

  get recipientPhoneNumber(): string {
    return Utils.countryIndonesiaPhoneNumber(
      controller.orderDetail.recipientPhoneNumber || '',
      true
    )
  }

  private priceFormat(price: number): string {
    return `Rp${Utils.currencyDigit(price)}`
  }

  private weightFormat(weight: number): string {
    if (weight > 99) {
      return `${Number((weight / 1000).toFixed(1))} kg`
    }

    return `${Number(weight)} gr`
  }

  get totalWeight(): number {
    return (controller.orderDetail.listProducts || []).reduce(
      (a, b) => a + (Number(b.weight) * Number(b.quantity)),
      0
    )
  }

  private dateFormat(date: string): string {
    return dayjs(date).format('DD MMM YYYY HH:mm')
  }

  private cancelOrder(): void {
    if (controller.orderDetail.shipmentId) {
      controller.cancelOrder({
        shipmentId: controller.orderDetail.shipmentId,
        note: this.cancelReason,
      })
    }
  }

  private getStatusAdjustment(): statusLabel {
    if (this.orderDetail.isOverpay) {
      return {
        title: 'Overpay Amount',
        value: 'Successfully Refunded'
      }
    }
    if (this.orderDetail.isUnderpay) {
      return {
        title: 'Underpay Amount',
        value: 'Paid'
      }
    }
    return {
      title: 'Amount',
      value: ''
    }
  }

  private setStatus(statusName: string): StatusDetail {
    const s = statusName.trim().replace(/ /g, '-').toLowerCase()
    const isStatusHasStyle = Object.keys(STATUS).includes(s)

    const defaultVal: StatusDetail = {
      color: '#9B581F',
      title: statusName === '' ? '-' : statusName,
      note: '',
    }
    return isStatusHasStyle ? STATUS[s] : defaultVal
  }

  beforeDestroy(): void {
    controller.setStatusCancelOrder('')
  }
}
