import { CancelOrderRequestInterface } from '@/data/payload/contracts/OrderRequest'

export class CancelOrderApiRequest implements CancelOrderRequestInterface {
  private shipmentId: string
  private reasonId: string
  private note: string

  constructor(shipmentId: string, reasonId: string, note: string) {
    this.shipmentId = shipmentId
    this.reasonId = reasonId
    this.note = note
  }

  public toPayload() {
    return {
      shipment_id: this.shipmentId,
      reason_id: this.reasonId,
      note: this.note
    }
  }
}
