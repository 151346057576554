export interface StatusDetail {
  title: string
  note: string
  color: string
}

export const STATUS: Record<string, StatusDetail> = {
  "unpaid": {
    title: "Unpaid",
    note: "The merchant has not paid the total underpayment!",
    color: "#9B581F",
  },
  "paid": {
    title: "Paid",
    note: "Underpayments will be automatically deducted from the disbursement of fund merchant transactions",
    color: "#36B37E",
  },
  "successfully-refunded": {
    title: "Successfully Refunded",
    note: "The merchant's total overpayment has been successfully returned!",
    color: "#36B37E",
  },
}
